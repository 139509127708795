<template>
  <ion-item v-if="!hidden"  lines="full" class="breadcrumbs" >
    <ion-breadcrumbs  class="crumbs"  router-direction="back" :max-items="items" :items-after-collapse="itemsCollapse" @click="popoverOpen=true"  @ionCollapsedClick="presentPopover($event)">
      <ion-breadcrumb :href="push(route)" class="crumbsNumber" v-for="(route,idx) in getRoutes()" :key="'br-' + idx" color="primary"> {{getName(route)}}</ion-breadcrumb> 
    </ion-breadcrumbs>
  </ion-item> 
  <ion-popover :is-open="popoverOpen" :event="event" @didDismiss="popoverOpen = false">
    <ion-content  class="ion-padding">
      <ion-list>
        <ion-item v-for="(breadcrumb, i) in collapsedBreadcrumbs" :href="breadcrumb.href" :key="'britem-' + i" :lines="i === collapsedBreadcrumbs.length - 1 ? 'none' : undefined">
          <ion-label>{{ breadcrumb.textContent }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-popover>
</template>

<script setup>

  import {IonBreadcrumb,IonBreadcrumbs,IonItem,IonContent,IonPopover,IonLabel,IonList} from '@ionic/vue'
  import { useRouter} from 'vue-router';
  import { ref, computed, onMounted,nextTick } from 'vue';

  // El state se utiliza en los eval para sacar el nombre, no borrar la importacion
  // eslint-disable-next-line no-unused-vars
  import { state, initCommonState } from '@/stores/state'
  import{timeout} from '@/services/Utils'
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n({ useScope: 'global' });

  const router=useRouter();
  const hidden = computed(() => router.currentRoute.value.matched[0]?.meta?.breadcrumb?.hidden);
  const collapsedBreadcrumbs=ref([]);
  const popoverOpen=ref(false);
  const event=ref(null);
  const items=ref(1);
  const itemsCollapse=ref(1)

  const getName = (route) => {
    return route && route.meta && route.meta.breadcrumb && route.meta.breadcrumb.name ? evaluar(route.meta.breadcrumb.name, route.meta.breadcrumb.name) : route.name;
  }

  const evaluar = (expr, defValue) => {
    try {
      if(expr.includes("state")){
        return eval(expr)
      }
      else{
        return t(eval(expr));
      }
    } catch (e) {
      return defValue;
    }
  }

  const getRoutes = () => {
    const allRoutes = router.getRoutes();
    const currentRoute = router.currentRoute.value.matched[0];
    return [...getRoutesParents(allRoutes, currentRoute), currentRoute];
  }

  const getRoutesParents = (routes, route) => {
    let parent = null;
    if (route && route.meta && route.meta.breadcrumb && route.meta.breadcrumb.parent) {
      parent = routes.filter(r => r.name == route.meta.breadcrumb.parent)[0];
    }
    if (parent) {
      return [...getRoutesParents(routes, parent) , parent];
    } else {
      return [];
    }
  }

  const push=(route) =>{
    const breadName = router.currentRoute.value.fullPath.split("/");
    let parametros=route.path.split("/");
    let ruta="";
    for(let i=1;i<parametros.length;i++){
        ruta=ruta+"/"+breadName[i];
    }
    return ruta;
  }

  const presentPopover=async(e)=> {
    collapsedBreadcrumbs.value = (e).detail.collapsedBreadcrumbs;
    event.value = e;
  }

  onMounted(async () => {
    console.log("mounted breadcrumb");
    await initCommonState();
    await nextTick()
    await checkOverflow();
    observeBreadcrumbs();
  });

  const observeBreadcrumbs = async () => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length || mutation.removedNodes.length) {
          nextTick(async() => {
            items.value=null;
            itemsCollapse.value=1;
            await checkOverflow()
          });
        }
      });
    });

    const crumbsElement = document.getElementsByClassName('crumbs')[0];
      if (crumbsElement) {
        observer.observe(crumbsElement, { childList: true, subtree: true});
      }
  };

  const checkOverflow= async()=> {
    await timeout(50);
    const contenedor=document.getElementsByClassName("breadcrumbs")
    let elementos=document.getElementsByClassName("crumbsNumber");
    let anchura=0;
    for(let i=0;i<elementos.length;i++){
      anchura=anchura+ elementos[i].clientWidth
    }
    const hasOverflow=anchura>screen.width-20;
    if (hasOverflow) {
      itemsCollapse.value=elementos.length-1;
      items.value=elementos.length-1;
      let overflow=true;
      while(overflow){
        anchura=0
        elementos=document.getElementsByClassName("crumbsNumber");
        await timeout(50);
        for(let i=0;i<elementos.length;i++){
          anchura=anchura+ elementos[i].clientWidth
        }
        console.log("anchu",anchura)
        if(anchura>screen.width-20){
          itemsCollapse.value--;
        }
        else{
          overflow=false;
        }
      }
    }
    else{
      items.value=elementos.length;
    }
  }

</script>
    
<style>
.breadcrumbs {
  --border-style: double;
  --border-width: 0 0 3px;
}
</style>