<template>
  <ion-header id="header">
    <ion-toolbar>
      <ion-select slot="end" class="ion-margin-horizontal ion-text-bold" :placeholder="$t('Proyecto')" v-model="state.proyecto" @ionChange="seleccionProyecto">
        <ion-select-option v-for="(proyecto) in proyectos" :key="'proyecto-'+proyecto.id" :value="proyecto">{{proyecto.nombre}}</ion-select-option>
      </ion-select>
    </ion-toolbar>
  </ion-header>
</template>

<script setup>
import { onMounted, ref } from 'vue'

import { IonHeader, IonToolbar, IonSelect, IonSelectOption } from '@ionic/vue';

import { state } from '@/stores/state'
import Proyectos from '@/services/Proyectos';
import { featureFromGeom } from '@/services/Utils';

/* Props */
defineProps({
  seccion: String,
});

/* Computed */
const proyectos = ref([]);

const seleccionProyecto = async () => {
  state.proyecto?.capas?.splice(0);
  const capasAux = await Proyectos.getCapasProyecto(state.proyecto.id);
  if (!state.proyecto.capas) {
    state.proyecto.capas = [];
  }
  capasAux.forEach((capa, i) => { state.proyecto.capas[i] = capa; });
  // Cargar features de las capas
  state.proyecto.capas?.forEach(async (capa) => {
    let datosCapa = await Proyectos.getCapa(state.proyecto.id, capa.id);
    capa.condiciones_tematicos = datosCapa.condiciones_tematicos;
    capa.documentos = datosCapa.documentos;
    if (state.proyecto.tipo == 'GEOGRAFICO') {
      capa.features = datosCapa.features.map(featureFromGeom);
      const camposCapa = await Proyectos.getCamposCapa(state.proyecto.id, capa.id);
      capa.atributos = camposCapa;
    }
  });
  if (state.proyecto.tipo == 'ORGANIZATIVO') {
    state.proyecto.actuaciones = await Proyectos.getActuaciones(state.proyecto.id, -1);
    state.proyecto.actuaciones?.forEach(async (actuacion) => {
      actuacion.campos = await Proyectos.getCamposActuacion(state.proyecto.id, -1, actuacion.id,"consulta");
    });
  }
}

onMounted(async () => {
  if(!proyectos.value?.length) {
    proyectos.value = await Proyectos.getProyectos();
  }
});

</script>
