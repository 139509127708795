<template>
    <cabecera-proyecto v-if="cabecera == 'proyecto'"></cabecera-proyecto>
    <ion-header id="header" v-else>
      <ion-toolbar>
        <ion-title v-if="title">{{ $t(title) }}</ion-title>
        <!-- Botón de refrescar para la web -->
        <ion-buttons slot="end">
            <ion-button @click="eventBus.emit('refresh', {mensaje: 'Queremos refrescar el contenido de la página'})">
                <ion-icon :src="refresh"></ion-icon>
            </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
  </template>
  
<script setup>
import { IonHeader, IonToolbar, IonTitle } from '@ionic/vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import CabeceraProyecto from './CabeceraProyecto.vue';
import { refresh } from 'ionicons/icons';
import eventBus from '../services/eventBus';

  
const router = useRouter();

const cabecera = computed(() => router.currentRoute.value.matched[0]?.meta?.cabecera);
const title = computed(() => router.currentRoute.value.matched[0]?.meta?.title);
  
</script>
  