<template>
  <ion-app>
    <ion-page>
      <cabecera-comun></cabecera-comun>
      <bread-crumb></bread-crumb>
      <ion-content :fullscreen="true" class="ion-padding">
        <ion-router-outlet slot="fixed"/>
      </ion-content>
      <ion-footer v-if="Auth.getUsuarioLogado()">
        <ion-toolbar>
          <ion-segment :value="getSegmnet()">
            <ion-segment-button @click="router.push({name:'empresas'})" value="empresas">
              <ion-icon aria-hidden="true" :icon="briefcase"></ion-icon>
              <ion-label>{{ $t('Empresas') }}</ion-label>
            </ion-segment-button>
            <ion-segment-button @click="router.push({name:'inspecciones'})" value="inspecciones">
              <ion-icon aria-hidden="true" :icon="map"></ion-icon>
              <ion-label>{{ $t('Inspecciones') }}</ion-label>
            </ion-segment-button>
            <ion-segment-button @click="router.push({name:'perfil'})" value="perfil">
              <ion-icon aria-hidden="true" :icon="person"></ion-icon>
              <ion-label>{{ $t('Perfil') }}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
      </ion-footer>
    </ion-page>
  </ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet, toastController, IonFooter, IonToolbar, 
  IonContent, IonIcon, IonLabel, IonPage,IonSegment,IonSegmentButton } from '@ionic/vue';
import { map, person, briefcase } from 'ionicons/icons';
import axios from 'axios';
import { useRouter } from 'vue-router';
import CabeceraComun from './components/CabeceraComun.vue';
import BreadCrumb from './components/BreadCrumb.vue';
import Auth from './services/Auth';
import { useI18n } from 'vue-i18n';
import { onMounted} from 'vue';

const {locale,t } = useI18n({ useScope: 'global' });

const router = useRouter();


onMounted(()=>{
  let language=window.navigator.language;
  if (language) {
    language = language.substring(0, 2);
    locale.value=language;
  }
})
const getSegmnet=()=>{
  return String(router.currentRoute.value.meta.segment);
}

axios.interceptors.response.use(function (response) {
  const data = response.data;
  if(data){
    data._completeResponse = response;
    return data;
  }
  return response;
}, async function (error) {
  if(!error?.config?.__hideDefaultError){
    console.error(`Error ${error.response.status} en llamada a ${error.request.responseURL}`, error);
    let message = error.response?.data?.message ? error.response?.data?.message : error.message,
      code = error.response?.status;
    let separador=message.split(": ");
    if(separador.length>1){
      message=separador[1];
    }
    else{
      message=separador[0];
    }
    if (error.response?.data?.errors) {
      error.response?.data?.errors.forEach(error => {
        error.message=t(error.message);
      });
      message = error.response?.data?.errors.map(e => `${e.message} [${e.field}]`).join('\n')
    }
    if(code == 401) { // Token inválido
      await router.push("/logout");
    }
    if(code==404 && error.response?.config?.url.includes('login')){
      message="Crendenciales no válidas";
    }
    const toast = await toastController.create({
      message: t(message),
      cssClass: 'error',
      duration: 3000,
      position: 'top',
    });
    await toast.present();
  }
  return Promise.reject(error);
});

</script>

<style>
ion-toast.error{
  margin-top: 8vh;
  --background: #ff9191;
}

ion-spinner {
    display: block;
    margin: auto;
    width: 8rem;
    height: 8rem;
  }

</style>